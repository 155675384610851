@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Adelia";
  src: url("./fonts/ADELIA.otf");
}

@font-face {
  font-family: "Geo";
  src: url("./fonts/Geo.ttf");
}
@font-face {
  font-family: "Demon";
  src: url("./fonts/demon.ttf");
}
@font-face {
  font-family: "Priest";
  src: url("./fonts/Alvin.ttf");
}

@font-face {
  font-family: "Flame";
  src: url("./fonts/Flame.ttf");
}

@font-face {
  font-family: "lon";
  src: url("./fonts/lon.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  /* font-family: "Roboto Slab"; */
}

/* styles.css */

body {
  /* padding: 20px;  */
  margin: 0;
  color: white;
  overflow-x: hidden;
  background-image: url("./assets/Max/bg.jpg");
  background-color: #3f7ff7;
  background-size: cover;
  background-attachment: fixed;
  
  
  
}

html {
  scroll-behavior: smooth;
}

/* .navbar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
} */

.navbar-brand {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.navbar-brand {
  width: 10vw;
}

.navbar-nav {
  display: flex;
  width: 70%;
  /* justify-content: space-between; */
  gap: 2rem;
  font-family: "Zou";
  color: white;
}





.nav-item {
  background-color: #ffffff;
  padding: 10px 20px;
  /* border-radius: 50px; */
}


.nav-link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 1.5rem;
  position: relative;
  font-weight: bold;
  
}

/* .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(221, 0, 0);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
} */

.nav-link:hover::after {
  width: 100%;
  color: red;
}

.nav-link.active {
  font-weight: bold;
}

.navbar-toggler {
  border: none;
  background: none;
  color: rgb(253, 6, 6);
  font-size: 1.2rem;
}

.container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Landing.css */




#meme-heading {
  padding: 40px;
  text-align: center;
}

.scroller {
  transition: transform 0.2s ease-in-out;
}

.scroller:hover {
  transform: scale(1.3);
}

#parent {
  /* box-shadow: -1px 1px 3px 3px #cee452; */
  border-radius: 10px;
  /* border: 2px solid rgb(102, 255, 0); */
  position: relative;

  width: 400px;
  height:400px;
  margin: 0 auto;
  border: 2px solid white;
  
}

#character,
#frontaccessory,
#hat,
#face,
#background {
  border-radius: 10px;
  width: 500px;
  position: absolute;
}

.btn {
  /* border: 2px solid rgb(247, 93, 93); */
  font-weight: bold;
  padding: 10px 10px;
  width: 100%;
  margin: 2px 0px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  background-color: black;
  border: 2px solid white;
  color: white; 
}

.btn:hover {
  transform: scale(1.05);
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5rem;
}

#selectionLayers {
  display: relative;
}

.spec {
  font-size: 18px;
  text-transform: capitalize;
  color: #ee8181;
  text-shadow: 1px 1px 1px #9e9e9e;
  width: 150px;
  font-weight: bold;
  font-family: 'Yellow';
}

.content {
  display: flex;
  height: 100vh;

  img {
    box-shadow: 1px 1px 10px rgb(243, 47, 47);
    width: 40%;
    height: fit-content;
    margin: 0 40px;
    border: 1px solid rgb(230, 15, 15);
  }
}

.game-text {
  padding: 100px 10vw;
}

.game-text h1 {
  font-size: 3rem;
}

.game-text p {
  font-size: 2rem;
}


.buy {
  text-align: center;
  padding: 20px;
  background-color: #d44a4a;
}

.buy h1 {
  font-family: "Yujin";
  text-align: center;
  font-size: 4rem;
  margin-bottom: 20px;
}

.options {
  font-family: "Yellow";
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.option {
  border: 2px solid #f3f583;
  padding: 30px;
  box-shadow: 0 0px 8px rgba(255, 255, 255, 0.563);

  h2 {
    font-size: 2rem;
    color: #f1f887;
  }

  h4 {
    color: #f7eb81;
    font-size: 1.5rem;
    padding: 10px 0;
  }
}

.option h2 {
  margin-top: 0;
}

.option h4 {
  margin-bottom: 5px;
}

.option p {
  margin-top: 0;
}

.token{
	padding: 50px;
  background-color: #e97d7d;
  height: 100vh;
}

.token h1 {
  margin-bottom: 40px;
  font-size: 4rem;
  text-align: center;
}


.supply 
{
	font-size: 3rem;
	display: flex;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    margin: 40px auto;
	color: black;
    width: fit-content;
    padding: 10px 20px;
    align-items: center;
    border-radius: 100px;

	span {
		padding: 10px 20px;
		background-color: #463221;
		color: white;
		border-radius: 100px;
	}

	p{
		padding: 10px 20px;
	}
}

.number 
{
	font-size: 2.5rem;
	display: flex;
    justify-content: center;
    background-color:  rgb(255, 17, 0);
    margin: 40px auto;
	color: white;
    width: fit-content;
    padding: 10px 20px;
    align-items: center;
    border-radius: 100px;

	button {
		padding: 10px 20px;
		background-color: #463221;
		color: white;
		border: 0px;
		cursor: pointer;
		border-radius: 100px;
	}

	p{
		padding: 10px 20px;
	}
}

.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #000000;
	color: white;
	text-align: center;
	padding: 1rem 0;
	display: flex;
	justify-content: center;
  
  z-index: 1000;

	p 
	{
		font-size: 20px;
	}
  }
  
 .footer {
   display: flex;
   flex-direction: row;
   align-items: center; 
    justify-content: space-around;
   padding: 35px;
  background-color: #000000;
   color: #ffffff;
    font-family: "mangat";
 }

 .social-links {
   display: flex;
   justify-content: center;
   gap: 10px;
 }

 .social-links a {
   color: #fff;
   font-size: 24px;
   transition: color 0.3s;
   padding: 5px;
 }

 .social-links a:hover {
   color: #ccc;
 }

 .flowing-text-container {
   overflow: hidden;
   white-space: nowrap;
   width: 100%;
   /* Adjust based on your design */
   position: relative;
   height: 40px;
   font-family: "Yujin";
   

   /* Adjust based on your design */
 }

 .flowing-text {
   display: flex;
   /* animation: flow 10s linear infinite; */
  color: rgb(255, 13, 13);
  font-weight: bold;

   /* Adjust the duration as needed */
 }

 .flowing-text span {
   padding: 0 15px;
   font-size: 30px;
   
   /* Adjust the spacing as needed */
 }

 @keyframes flow {
   0% {
     transform: translateX(100%);
   }

   100% {
     transform: translateX(-100%);
   }
 }

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}



.blur-background {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

/* Custom CSS to hide scrollbars */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}